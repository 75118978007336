/* @jsx jsx */

import { jsx, css } from "@emotion/react"

import Layout from "@src/components/layout"
import SEO from "@src/components/seo"
import { Body, H1, H2, H3, H4, H5, H6, Section } from "@src/components"
import { Button, LinkButton } from "@src/components/buttons"

const StyleGuide = () => (
  <Layout locale="nl">
    <Section background="white">
      <div
        css={css`
          font-family: "GT Flexa Trial";
          font-size: 75px;
          text-transform: uppercase;
        `}
      >
        H1 Heading Desktop
      </div>
      <div
        css={css`
          font-family: "GT Flexa Trial";
          font-size: 54px;
          text-transform: uppercase;
        `}
      >
        H2 Heading Desktop
      </div>
      <div
        css={css`
          font-family: "GT Flexa Trial";
          font-size: 42px;
          text-transform: uppercase;
        `}
      >
        H3 Heading Desktop
      </div>
      <div
        css={css`
          font-family: "GT Flexa Trial";
          font-size: 36px;
          text-transform: uppercase;
        `}
      >
        H4 Heading Desktop
      </div>
    </Section>
  </Layout>
)

export default StyleGuide
